<template>
  <div>
    <div class="no-scrollbar text-shadow" style="white-space:nowrap;font-size:14px;margin:0;overflow:auto;opacity: 0.8;">
      <ul class="list-unstyled text-shadow" style="white-space:nowrap;font-size:14px;margin:0;">
        <template v-if="hasGroup()">
          <template v-for="(item,index) in groups">
            <li v-if="!(getGroupItems(item.guid).length==0 && !item)" class="hotspotItem" style="display:inline-block;width:auto;margin:5px;pointer-events:auto;vertical-align: top;position:relative;">
              <div style="position:relative;cursor:pointer;" class="text-truncate group" :class="{'active':groupGuid==item.guid}" @click="setEditGroup(item)">
                <div style="display:flex;justify-content:space-between;">
                  <!--<span @click.stop="groupMove(item,-1)"><i class="fas fa-angle-left"></i></span>-->
                  <button type="button" class="arrowbtn text-shadow" @click.stop="groupMove(item.guid,-1)"><i class="fas fa-chevron-left"></i></button>
                  <span v-if="groupGuid==item.guid" style="padding:0 3px" @click="setEditGroup(item)">
                    <i class="fa fa-cog"></i>
                  </span>
                  <span v-else style="padding:0 3px">
                    {{getGroupItems(item.guid).length}}
                  </span>
                  <!--<span @click.stop="groupMove(item,1)"><i class="fas fa-angle-right"></i></span>-->
                  <button type="button" class="arrowbtn text-shadow" @click.stop="groupMove(item.guid,1)"><i class="fas fa-chevron-right"></i></button>
                </div>
                <a style="color:#fff;display:block" :class="{ 'text-danger':!checkGroupUniqueName(item) }">
                  <!--<span class="badge bg-danger"></span>-->
                  <div class="" style="text-align:center;font-size:12px;">
                    <i v-if="item.hide" class="far fa-eye-slash mr-1"></i>
                    <template v-if="item.type=='Blog'">
                      <i class="far fa-file-alt"></i>
                    </template>
                    <template v-else-if="item.type=='Gallery'">
                      <i class="far fa-images"></i>
                    </template>
                    <template v-else>
                      <span v-if="groupGuid==item.guid">
                        <i class="far fa-folder-open"></i>
                      </span>
                      <span v-else>
                        <i class="far fa-folder"></i>
                      </span>
                    </template>
                    {{item.title||'公共空间'}}
                  </div>
                </a>
              </div>
            </li>
          </template>
        </template>
          <li style="display:inline-block;margin:5px;pointer-events:auto;vertical-align: bottom;">
            <div style="position:relative;cursor:pointer;" class="text-truncate group" @click="addGroup()">
              <div style="display:flex;justify-content:center;">
                <span>
                  <i class="fas fa-folder-plus"></i>
                </span>
              </div>
              <a style="color:#fff;display:block">
                <div class="" style="text-align:center;font-size:12px;">
                  添加分组
                </div>
              </a>
            </div>
          </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import { uuid } from 'vue-uuid'
  import PellEditor from '../../PellEditor'

  export default {
    components: {
      PellEditor
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        rooms: {}
        //groups: []
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getComonent: {
        default: {}
      },
      getFunc: {
      }
    },
    computed: {
      form() {
        return this.publicData.form || {}
      },
      $v() {
        return this.publicData.$v
      },
      groupGuid() {
        return this.publicData.groupGuid
      },
      editItemGuid() {
        return this.publicData.editItemGuid
      },
      editItemIndex() {
        for (var i in this.form.items) {
          if (this.form.items[i].guid == this.editItemGuid) {
            return i
          }
        }
        return null
      },
      groups() {
        return this.getGroups()
      }
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          getRoom: this.getRoom
        },
        events: {
          showEditModel: {
            target: 'groupSettingModel',
            name: 'show'
          },
          addGroup: {
            target: 'groupSettingModel',
            name: 'addGroup'
          }
        }
      })
    },
    destroyed() {
    },
    methods: {
      getGroups() {
        var groups = this.form.groups || []
        var g
        for (var i in this.form.items) {
          var item = this.form.items[i]
          //if (!item.groupName) {
          //    continue
          //}
          if (!(g = this.isInGroup(item.roomId, groups))) {
            g = this.roomToGroup(this.getRoom(item.roomId))
            groups.push(g)
          }
          item.groupGUID = g.guid
        }
        return groups
      },
      getRoom(id) {
        return this.getFunc({ target: 'Data', name: 'getRoom' })(id)
      },
      roomToGroup(room) {
        if (!room) {
          return this.newGroup(null, { guid: null })
        }
        return this.newGroup(room.title, { guid: room.id })
      },
      setEditGroup(item) {
        if (item.type == 'Blog') {
          this.getFunc('renewSence')('blog')
        } else if (item.type == 'Gallery') {
          this.getFunc('renewSence')('gallery')
        } else {
          this.getFunc('renewSence')('default')
        }
        var groupGuid = item.guid
        if (this.groupGuid == groupGuid && groupGuid) {
          this.$emit('showEditModel')
        }
        //this.changeGroup(groupGuid)
        if (!groupGuid) {
          var editItemGuid = this.getGroupItems(item.guid)[0].guid
          this.getFunc('changePublicData')({ editItemGuid, })
        } else {
          this.getFunc('changePublicData')({ groupGuid, })
        }
        //  this.$forceUpdate()
      },
      addGroup() {
        this.$emit('addGroup')
      },
      groupMove2(roomId, relative) {
        var indexs = {}
        var gi = 0
        for (var i in this.form.items) {
          var groupIndex = null
          for (var i1 in indexs) {
            if (indexs[i1].gguid == this.form.items[i].roomId) {
              groupIndex = indexs[i1].groupIndex
            }
          }
          if (groupIndex == null) {
            groupIndex = gi
            gi++
          }
          indexs[this.form.items[i].guid] = { index: i, groupIndex: groupIndex, gguid: this.form.items[i].roomId }
        }
        var rgi = null
        for (var i in indexs) {
          if (indexs[i].guid == roomId) {
            rgi = Number(indexs[i].groupIndex)
            break;
          }
        }
        for (var i in indexs) {
          if (indexs[i].groupIndex == rgi + relative) {
            indexs[i].groupIndex = rgi
          }
        }
        for (var i in indexs) {
          if (indexs[i].gguid == roomId) {
            indexs[i].groupIndex = rgi + relative
          }
        }
        var eig = this.form.items[this.editItemIndex].guid
        this.form.items.sort((a, b) => {
          if (indexs[a.guid].groupIndex != indexs[b.guid].groupIndex) {
            return indexs[a.guid].groupIndex - indexs[b.guid].groupIndex
          }
          return indexs[a.guid].index - indexs[b.guid].index
        })
      },
      groupMove(groupGuid, relative) {
        var groups = this.groups
        var fromi = null
        for (var i in groups) {
          if (groups[i].guid == groupGuid) {
            fromi = Number(i)
          }
        }
        var to = fromi + relative
        var item = groups[fromi]
        groups.splice(fromi, 1)
        groups.splice(to, 0, item)
        var indexs = {}
        for (var i in this.form.items) {
          var groupIndex = null
          for (var i1 in groups) {
            if (groups[i1].guid == this.form.items[i].roomId) {
              groupIndex = i1
            }
          }
          indexs[this.form.items[i].guid] = { index: i, groupIndex: groupIndex, gguid: this.form.items[i].roomId }
        }
        //var rgi = null
        //for (var i in indexs) {
        //    if (indexs[i].gguid == groupName) {
        //        rgi = Number(indexs[i].groupIndex)
        //        break;
        //    }
        //}
        //for (var i in indexs) {
        //    if (indexs[i].groupIndex == rgi + relative) {
        //        indexs[i].groupIndex = rgi
        //    }
        //}
        //for (var i in indexs) {
        //    if (indexs[i].gguid == groupName) {
        //        indexs[i].groupIndex = rgi + relative
        //    }
        //}
        console.log(indexs)
        var eig = this.form.items[this.editItemIndex].guid
        this.form.items.sort((a, b) => {
          if (indexs[a.guid].groupIndex != indexs[b.guid].groupIndex) {
            return indexs[a.guid].groupIndex - indexs[b.guid].groupIndex
          }
          return indexs[a.guid].index - indexs[b.guid].index
        })
        console.log(this.form.items)
        //for (var i in this.form.items) {
        //  if (this.form.items[i].guid == eig) {
        //    this.editItemIndex = Number(i)
        //    break
        //  }
        //}
        //this.makegroup()
      },

      //changeGroup(guid) {
      //  console.log('changeGroup', guid)
      //  var groupItems = this.getFunc({ target: 'Data', name: 'getGroupItems' })(guid)
      //  this.getFunc('changePublicData')({ groupItems, })
      //},
      hasGroup() {
        return true
        for (var i in this.groups) {
          if (this.groups[i].title) {
            return true
          }
        }
        return false
      },
      getGroupItems(groupGuid) {
        return this.getFunc({ target: 'Data', name: 'getGroupItems' })(groupGuid) || []
      },
      checkGroupUniqueName(p) {
        for (var i in this.form.groups) {
          var item = this.form.groups[i]
          if (item.guid != p.guid && item.title == p.title) {
            return false
          }
        }
        return true
      },
      newGroup(groupName, data) {
        return {
          title: groupName,
          description: '',
          audioAutoPlay: true,
          audioLoop: false,
          file: null,
          audio: null,
          ...data
        }
      },
      isInGroup(groupName, groups) {
        for (var i in groups) {
          if (groups[i].guid == groupName) {
            return groups[i]
          }
        }
        return false
      }
    },
  }
</script>
<style scoped>
  .GroupItem {
    display: inline-block;
    width: auto;
    margin: 2px;
    pointer-events: auto;
    vertical-align: top;
    position: relative;
  }

  .group {
    border-bottom: 2px solid #0000;
    /*background-color: #0008;*/
    padding: 2px 4px;
  }

    .group.active {
      border-bottom: 2px solid #ffb400;
      /*background-color: #00f8*/
    }

  .arrowbtn {
    background-color: transparent;
    border: 0;
    border-radius: 4px;
    color: #fff;
    outline: 0;
  }

  .hotspotItem {
    display: inline-block;
    margin: 5px;
    width: 4.1em;
    vertical-align: top;
  }

    .hotspotItem.active {
      margin: 4px;
      border: 1px solid #fff;
      box-shadow: #000 0 0 2px 0;
    }

    .hotspotItem .badge {
      position: absolute;
      right: 2px;
    }
</style>
